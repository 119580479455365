import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../case.service';
import { CreditorService } from '../../creditor/creditor.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-creditor-case-updatebalance',
  templateUrl: './updatebalance.component.html',
  styleUrls: ['./updatebalance.component.scss'],
})

export class CreditorCaseUpdateBalanceComponent implements OnInit, AfterViewInit, OnDestroy {

  math = Math;
  // Wrapper
  @ViewChild('tabs') tabGroup: MatTabGroup;
  public bulkActionForm: UntypedFormGroup;
  public onlyRelated: boolean;
  public filterType: 'case' | 'legal' | 'affiliate_case' | 'customer_contact' | 'packager_non_transferred' = null;
  // End of wrapper

  public authUser: User;
  public isLoadingExport = 0;
  public submittingDistribution: boolean;

  public filtersReady = false;
  public caseListFilter: CaseListFilter;

  public percentageReduction: number;

  // Datatable
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoadingCases                      = 0;
  public initialSelection                    = [];
  public allowMultiSelect                    = true;
  public selection                           = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public showInvoiceStatus: boolean;
  public pageSelected                        = false;
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Case>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public creditorDataSource: Array<string> = [];
  public negotiatorCreditorCols: Array<string> = [
    'creditor', 'creditor_status', 'debt_amount', 'negotiated_amount', 'percent_reduction'
  ];
  public userIsCreditor: boolean = false;

  public isManager: boolean = false;

  // End Datatable
  private subscriptions: Array<Subscription> = [];
  public isUserCreditorNegotiator            = false;

  public displayedColumns: string[] = [
    'id_card',
    'last_name',
    'initial_balance',
    'updated_balance',
    'actions',
  ];

  constructor(
    public globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private creditorService: CreditorService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 20, length: 1 };
    this.defaultSort            = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.fetchCreditorCases();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  public fetchCreditorCases(statusId = null) {
    this.fetchCases(statusId);
  }

  private fetchCases(statusId = null): void {
    this.clearSelection();  //  Reset global select

    const relations = ['packager_status', 'case_invoice_status', 'call_status', 'packager', 'affiliate', 'product', 'lead.affiliate',
      'payment_status', 'status', 'latest_case_status_log', 'oldest_case_pending_document', 'creditors', 'doc_status', 'case_status_logs', 'expense', 'distribution'];
    if (this.filterType === 'customer_contact') {
      relations.push('notification_histories.notifiable');
      relations.push('notification_histories.staff');
    }

      let whereNotStatuses = [335,466,113]
      let whereStatuses = [statusId];
      let case_creditor_statuses = [null];
      let cc_not_statuses = [14,42,47,11];
    // push statuses and case_creditor_statuses here
    this.caseListFilter = {
      ...this.caseListFilter,
      page: this.paginatorConfig.pageIndex + 1,
      per_page: this.paginatorConfig.pageSize,
      not_statuses: whereNotStatuses,
      cc_not_statuses: cc_not_statuses,
      case_creditor_statuses: case_creditor_statuses
    };
    // if statusId is not null, push it to the caseListFilter wherenotstatuses
    console.log(statusId);
    if(statusId === null){
      this.caseListFilter.not_statuses.push(364);
      this.caseListFilter.cc_not_statuses.push(25);
    }

    const requestData = MainBaseApiService.convertFiltersForRequest(
      { ...this.caseListFilter, with: relations }, 'get'
    );

    this.dataSource = new MatTableDataSource<Case>([]);
    this.isLoadingCases++;
    this.subscriptions.push(
      this.caseService.index(requestData).pipe(finalize(() => this.isLoadingCases--)).subscribe(
        result => {
          this.clearSelection();
          this.dataSource             = new MatTableDataSource<Case>(result.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        err => console.error(err)
      )
    );
  }

  private clearSelection() {
    this.pageSelected = false;  //  Hide global select
    // delete this.caseListFilter.cases;
    this.creditorDataSource = [];
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
    this.creditorDataSource = [];
  }

  public acceptSettlementAmount(element) {
    let input = document.getElementById(element.id) as HTMLInputElement;
    const case_id = element.case.id;
    const requestData = {
      initial_balance: input.value,
      creditor_id: element.creditor_id,
      amount_updated_at: DateTime.now().toISO(),
    };
    this.creditorService.saveLoanCreditorsData(case_id, element.id, requestData).subscribe(
      res => {
        this.fetchCreditorCases();
      },
      err => {
        console.error(err);
      }
    );
  }
}