import { Component, Input, OnInit } from '@angular/core';
import { StatsService } from './stats.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import {MatTabsModule} from '@angular/material/tabs';
import { NgIf, NgForOf } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector:    'app-header',
    templateUrl: './stats.component.html',
    standalone: true,
    imports: [MatTabsModule, NgIf, NgForOf, CurrencyPipe, MatProgressSpinnerModule],
    styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
    @Input() notifications;
    public stats = [];
    public name: string;
    public uuid: string;
    public errorTitle: string;
    public errorMessage: string;
    public isLoading = 1;
    
    constructor(private route: ActivatedRoute,
                private statsService: StatsService
                ) {
        this.route.params.subscribe(params => {
            this.uuid = params.uuid;
        });
    }

    ngOnInit(): void {
        this.getStats();
    }
  
    public getStats(): void {
        this.statsService.getStats(this.uuid).pipe(finalize(() => this.isLoading--)).subscribe(
            result => {
                if(result.data.error) {
                    this.errorTitle = result.data.error;
                    this.errorMessage = result.data.message;
                    return;
                }
                this.name = result.data.user;
                this.stats.push(result.data);
                console.log(this.stats);
            }
        ),
        error => {
            console.log(error);
            this.errorTitle = error.statusText;
            this.errorMessage = error.error.message;
        }
    }
}
