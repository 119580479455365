<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h2>{{ "CASES.details.generate-proposal" | translate }}</h2>
    <a *ngIf="data.case.propuesta_modelo" mat-raised-button color="primary" target="_blank"
       href="{{storageUrl + data.case.propuesta_modelo.location}}" download>
      Download
    </a>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="formActive">
        
    <div class="form-group">
      <!-- <label for="debt_level">{{"CASES.single.debt-level" | translate}}</label>
      <input type="number" class="form-control" id="debt_level" formControlName="debt_level"> -->
      <app-input type="number" appearance="outline" formControlName="debt_level" [showClear]="false" [extraLabel]="true"
                [label]="'CASES.single.debt-level' | translate" [readonly]="!allowedUsers.includes(authUser.id)" [hidden]="true">
      </app-input>
      <div *ngIf="form.get('debt_level').touched && form.get('debt_level').errors?.required"
          class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div>

    <div class="form-group input-grid-left">
      <!-- <label for="disposable_income">{{ 'CASES.financial_overview.table.disposable_income' | translate }}</label>
      <input type="number" class="form-control" id="disposable_income" formControlName="disposable_income"> -->
      <!-- <mat-label>{{ 'CASES.financial_overview.table.disposable_income' | translate }}</mat-label> -->
      <!-- <app-input type="number" appearance="outline" formControlName="disposable_income" [showClear]="false" [extraLabel]="true"
                [label]="'CASES.financial_overview.table.disposable_income' | translate">
      </app-input>
      <div *ngIf="form.get('disposable_income').touched && form.get('disposable_income').errors?.required"
          class="alert alert-danger">{{"SHARED.field-required" | translate}}</div> -->
      <app-input class="grid-item" type="select" formControlName="disposable_income" [extraLabel]="false"
                [label]="'CASES.single.plan_based_on' | translate" [selectOptions]="disposableOptions"
                [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true" *ngIf="!showCustomFields">
      </app-input>

      <div class="grid-item" *ngIf="showCustomFields">
        <app-input type="number" appearance="outline" formControlName="custom_disposable_income" [showClear]="false" [extraLabel]="false"
                  label="Custom amount">
        </app-input>
      </div>

      <div class="grid-item" *ngIf="showCustomFields">
        <button  mat-raised-button color="primary" type="button" (click)="addToCustom()">
          Add
        </button>
      </div>

      <label [hidden]="!allowedUsers.includes(authUser.id)">
        <input type="checkbox" formControlName="automationToggle" />
        Pause Automation
      </label>
    </div>
    
    <div class="input-grid-center">
      <!-- <div class="form-group">
        <label for="total_income">{{"CASES.single.total-income" | translate}}</label>
        <input type="number" class="form-control" id="total_income" formControlName="total_income">
        <div *ngIf="form.get('total_income').touched && form.get('total_income').errors?.required"
            class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
      </div> -->



      <!-- <div class="form-group">
        <label for="total_expenses">{{"CASES.single.total-expenses" | translate}}</label>
        <input type="number" class="form-control" id="total_expenses" formControlName="total_expenses">
        <div *ngIf="form.get('total_expenses').touched && form.get('total_expenses').errors?.required"
            class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
      </div> -->

      <!-- <div class="form-group">
        <label for="total_assets">{{"CASES.single.total-assets" | translate}}</label>
        <input type="number" class="form-control" id="total_assets" formControlName="total_assets">
        <div *ngIf="form.get('total_assets').touched && form.get('total_assets').errors?.required"
            class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
      </div> -->

      <!--<div class="form-group">
        <app-input type="number" appearance="outline" formControlName="client_final_payment" [showClear]="false" [extraLabel]="true"
                  label="Client final payment (including fee)" readonly>
        </app-input>
      </div>-->

      <!-- <div class="form-group">
        <label for="legal_fees">{{"CASES.single.legal-fees" | translate}}</label>
        <input type="number" class="form-control" id="legal_fees" formControlName="legal_fees">
        <div *ngIf="form.get('legal_fees').touched && form.get('legal_fees').errors?.required"
            class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
      </div> -->

      <div class="grid-item">
          <!-- client 12 month proposal -->
          <app-input type="number" appearance="outline" formControlName="client_proposal" [showClear]="false" [extraLabel]="false"
                    label="Client Term" [readonly]="!allowedUsers.includes(authUser.id)">
          </app-input>
      </div>

      <div class="form-group grid-item">
        <app-input type="number" appearance="outline" formControlName="distribution_fee" [showClear]="false" [extraLabel]="false"
                  label="Distribution Fee" [readonly]="!allowedUsers.includes(authUser.id)">
        </app-input>
      </div>

      <div class="form-group" grid-item>
        <!-- <label for="offer_to_creditors">{{"CASES.details.offer-to-creditors" | translate}}</label>
        <input type="number" class="form-control" id="offer_to_creditors" formControlName="offer_to_creditors"> -->
        <app-input type="number" appearance="outline" formControlName="actual_disposable" [showClear]="false" [extraLabel]="false"
                  label="Offer to Creditors" [readonly]="!allowedUsers.includes(authUser.id)">
        </app-input>
        <div *ngIf="form.get('actual_disposable').touched && form.get('actual_disposable').errors?.required"
            class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
      </div>
    </div>
    
    <div class="form-group">
      <!-- iterate over case creditors and display the name and debt amount in the table -->
      <table class="table" formArrayName="creditors">
        <thead>
        <tr>
          <th>Creditor</th>
          <th>Client Debt Amount</th>
          <th>DM Amount</th>
          <th>Monthly Payment</th>
          <th>Final Payment</th>
          <th>Term</th>
        </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let formGroup of getFormArray().controls let i = index">
            <tr [formGroupName]="i">
              <td>{{ form.get('creditors.' + i + '.creditor_name').value }}</td>
              <td>{{ form.get('creditors.' + i + '.initial_balance').value | currency }}</td>
              <td>{{ form.get('creditors.' + i + '.debt_amount').value }}</td>
              <td>
                <!-- input field with offer_to_creditors -->
                <app-input type="number" appearance="outline" formControlName="offer_to_creditors" [showClear]="false"
                          [extraLabel]="false" label="Offer to Creditors">
                </app-input>
              </td>
              <td>
                <!-- input field with final_payment -->
                <app-input type="number" appearance="outline" formControlName="final_payment" [showClear]="false"
                          [extraLabel]="false" label="Final Payment">
                </app-input>
              </td>
              <td>
                <!-- input field with term -->
                <app-input type="number" appearance="outline" formControlName="creditor_term" [showClear]="false" [extraLabel]="false"
                          label="Term">
                </app-input>

                <div *ngIf="form.get('creditors.' + i + '.creditor_term').touched && form.get('creditors.' + i + '.creditor_term').errors?.creditorTermInvalid"
                  class="alert alert-danger">
                      warning: term too big
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!-- <div class="form-group"> -->
      <!-- <label for="term">{{"CASES.details.term" | translate}}</label>
      <input type="number" class="form-control" id="term" formControlName="term" readonly> -->
      <!-- <app-input type="number" appearance="outline" formControlName="term" [showClear]="false" [extraLabel]="true"
                 readonly [label]="'CASES.details.term' | translate">
      </app-input>
      <div *ngIf="form.get('term').touched && form.get('term').errors?.required"
           class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
    </div> -->

    <!-- <button type="button" mat-stroked-button color="primary" (click)="stepPayment()" style="margin-bottom: 1.5rem">
      {{ (showStepPayment ? "CASES.details.step-payment-remove" : "CASES.details.step-payment-add") | translate }}
    </button>

    <div [@inOutAnimation] *ngIf="showStepPayment" class="form-group">
           <mat-divider></mat-divider>
      <div class="form-group">
        <label for="step_period">{{"CASES.details.step-payment-month" | translate}}</label>
        <input type="number" class="form-control" id="step_period" formControlName="step_period">
      </div>

      <div class="form-group">
        <label for="step_offer">{{"CASES.details.step-payment-offer" | translate}}</label>
        <input type="number" class="form-control" id="step_offer" formControlName="step_offer">
      </div>
      <mat-divider></mat-divider>
    </div> -->
    
    <div class="input-grid-center">
      <!--<div class="form-group">-->
        <div class="grid-item">
          <app-input type="number" appearance="outline" formControlName="unifyePlan" [showClear]="false" [extraLabel]="false" readonly
                label="Unifye plan allocation" readonly>
          </app-input>
        </div>
        <!-- <label for="dividend">{{"CASES.details.dividend" | translate}}</label>
        <input type="number" class="form-control" id="dividend" formControlName="dividend" value="100" readonly> -->
        <app-input class="grid-item" type="number" [hidden]="true" appearance="outline" formControlName="dividend" [showClear]="false" [extraLabel]="true"
          [readonly]="!allowedUsers.includes(authUser.id)" [label]="'CASES.details.dividend' | translate">
        </app-input>
        <div *ngIf="form.get('dividend').touched && form.get('dividend').errors?.required"
            class="alert alert-danger grid-item">{{"SHARED.field-required" | translate}}</div>
          <app-input class="grid-item" type="number" appearance="outline" formControlName="unallocated" [showClear]="false" [extraLabel]="false" readonly
                      label="Unallocated funds">
          </app-input>
      <!--</div>-->
        <div class="row" style="height: 100px;">
          <!--<div class="col-6 d-flex align-items-center">
            {{ "CASES.details.payment-date" | translate }}
          </div>-->
          <div class="col-6" style="margin: 0; padding: 0;">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASES.details.payment-date" | translate }}</mat-label>
              <input matInput [matDatepicker]="paymentDate" [min]="todayDate" formControlName="payment_date">
              <mat-datepicker-toggle matSuffix [for]="paymentDate"></mat-datepicker-toggle>
              <mat-datepicker #paymentDate color="primary"></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="form.get('payment_date').touched && form.get('payment_date').errors?.required"
              class="alert alert-danger">{{"SHARED.field-required" | translate}}</div>
        </div>
    </div>
    
    <!-- <div class="form-group">
      <label for="creditor_payment_account">
        {{"CASES.details.creditor_payment_account" | translate}}
      </label>
      <input type="text" class="form-control" id="creditor_payment_account" formControlName="creditor_payment_account">
    </div> -->

    <!-- <div class="form-group">
      <label for="payment_reference">{{"CASES.details.payment_reference" | translate}}</label>
      <input type="text" class="form-control" id="payment_reference" formControlName="payment_reference">
    </div> -->

   

  </form>

  <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">

    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal(null, true)" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button  mat-raised-button color="primary" (click)="submitForm()" [disabled]="(unallocated !== 0.00 || form?.get('creditors').invalid) && (!isAutomationPaused) || disableProposalButtons">
      {{"SHARED.generate" | translate}}
    </button>
    <!-- *ngIf="(!isLoading && !isLocked) || isAllowedToGenerateProposal()" -->
    <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
