<div class="container">
  <div class="row">
    <div class="col-12">
      <div *ngIf="!isLoadingCases">
        <table mat-table [dataSource]="dataSource">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- ID Number -->
          <ng-container matColumnDef="id_card">
            <th mat-header-cell *matHeaderCellDef>{{ 'USERS.model.id_card' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.case.client.id_card }}</td>
          </ng-container>
          <!-- Last Name -->
          <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.case.client.last_name }}</td>
          </ng-container>
          <!-- Initial Balance -->
          <ng-container matColumnDef="initial_balance">
            <th mat-header-cell *matHeaderCellDef>{{ 'CREDITOR_PORTAL.declared_amount' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.initial_balance | currency }}</td>
          </ng-container>
          <!-- Updated Balance -->
          <ng-container matColumnDef="updated_balance">
            <th mat-header-cell *matHeaderCellDef>{{ 'CREDITOR_PORTAL.update_debt' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input type="number" value="" [id]="element.id" />
            </td>
          </ng-container>
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="pl-2"></th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex" class="pl-2" *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
                <div>
                  <button mat-raised-button class="btn-small ml-2" color="primary" (click)="acceptSettlementAmount(element)">{{ 'SHARED.save' | translate }}</button>
                </div>
              </div>
            </td>
          </ng-container>
        </table>
      </div>
      <!-- Loader -->
      <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- No Data -->
      <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
  </div>
</div>