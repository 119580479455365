import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ReportService } from '../report.service';
import { AutomatedCreditorReportComponent } from './automated-creditor-report/automated-creditor-report.component';
import { NegotiationReportComponent } from './negotiation-report/negotiation-report.component';
import { PackagerSalesReportComponent } from './packager-sales-report/packager-sales-report.component';
import { PaymentReceivedModalComponent } from './payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './sales-report-modal/sales-report-modal.component';
import { BulkDmModalComponent } from './bulk-dm-report/bulkdm-report-modal.component';
import { CreditorNegotiationModalComponent } from './creditor-negotiator-report/creditor-negotiation-modal.component';
import { CreditorDebtModalComponent } from './creditor-debt-report/creditor-debt-modal.component';
import { CreditorDMAcceptedModalComponent } from './creditor-dm-accepted-report/creditor-dm-accepted-modal.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector:    'app-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styles:      [],
})
export class FinancialReportListComponent implements OnInit {
  public authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;
  public isLoading = 0;
  public allowedUsers = [752733, 751600, 787448, 747150, 771197];

  public usersWithHistoricReports: Array<User> = [];
  public historicJobs: Array<AppJob> = [];
  public historicForm = 'historic';
  public form: UntypedFormGroup;
  public appJobsHistoric: Array<AppJob> = [];
  public displayedHistoricColumns: string[] = [
    'label', 'attempts'
  ];


  public displayedColumns: string[] = [
    'name',
    'description',
    'actions',
  ];

  public reportOptions: Array<any> = [
    {
      name:        'Payment Received',
      description: 'Generate Payment Received Report',
      action:      this.paymentReceivedModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Sales Report',
      description: 'Generate Sales Report',
      action:      this.salesReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Packager Sales Report',
      description: 'Generate Packager Sales Report',
      action:      this.packagerSalesReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Automated Creditor Report',
      description: 'Generate Automated Creditor Report',
      action:      this.automatedCreditorReport.bind(this),
      disabled:    false,
    },
    {
      name:        'Negotiation Report',
      description: 'Generate Negotiation Report',
      action:      this.negotiationReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Bulk DM Report',
      description: 'Generate Bulk DM Report for a specific creditor',
      action:      this.bulkDMReport.bind(this),
      disabled:    false,
    },
    {
      name:        'Creditor Negotiation Report',
      description: 'Generate Creditor Negotiation Report',
      action:      this.creditorNegotiationReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Creditor Debt Report',
      description: 'Generate Creditor Debt Report',
      action:      this.creditorDebtReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'DM Accepted Report',
      description: 'Generate DM Accepted Report',
      action:      this.creditorDMAcceptedModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Propuesta Creada Export',
      description: 'Generate Propuesta Creada Export',
      action:      this.dmPropCreadaExport.bind(this),
      disabled:    false,
    }
  ];

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchQueuedJobs();
    this.getUsersWithHistoricReports();
    this.buildForm();
  }

  public buildForm(): void {
    this.form = this.fb.group({
        user_selection: [null],
    });
    this.form.get('user_selection').valueChanges.subscribe((user) => {
        console.log(user);
        this.fetchUserJobs(user);
    });
  }

  public fetchQueuedJobs(): void {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export', 'with[]': 'user'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Queued jobs fetched successfully');
              this.appJobs = result.data;
            },
        );
  }

  public paymentReceivedModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PaymentReceivedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public salesReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(SalesReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public packagerSalesReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PackagerSalesReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public automatedCreditorReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(AutomatedCreditorReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public negotiationReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(NegotiationReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public bulkDMReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(BulkDmModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public creditorNegotiationReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(CreditorNegotiationModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public creditorDebtReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(CreditorDebtModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public creditorDMAcceptedModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(CreditorDMAcceptedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public bulkDMreportAction($event): void {
    if (this.appJobs.some(job => job.payload.params.creditor_id === 'all')) {
      this.toastr.error('A bulk DM report for all creditors is already in the queue');
      return;
    }
    // if it's during working hours prevent the user from generating the report
    const date = new Date();
    const day = date.getDay();
    const hours = date.getHours();
    if (day >= 1 && day <= 5 && hours >= 9 && hours < 18) {
        Swal.fire({
            title: 'Generate Mass Bulk DM Report',
            html: 'You can only generate a bulk DM report outside of working hours.<br>Working hours are from Monday to Friday, 09:00 to 18:00.',
            icon: 'warning',
            confirmButtonText: 'Ok',
        });
      return;
    }


    Swal.fire({
        title: 'Generate Mass Bulk DM Report',
        html: 'Are you sure you want to generate a bulk DM report for all creditors?<br>This action will take a while to complete.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result) => {
        if (result.isConfirmed) {
            const requestData = {
                creditor_id: 'all',
                amount_paid: 125,
                report_type: 'full_bulk_dm_report',
            };
            this.isLoading++;
            this.reportService.generateBulkDmReport(requestData).pipe(finalize(() => this.isLoading--))
                .subscribe(
                    res => {
                    this.toastr.success(res.message);
                },
                    err => {
                    console.error(err);
                    this.toastr.error('Failed to generate report');
                },
            );
        }
    });
  }

  public fetchUserJobs(user): void {
    console.log(user);
    this.isLoadingJobs++;
    this.reportService.getHistoricalReports(user).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Historical reports fetched successfully');
              this.historicJobs = result.data;
              console.log(result.data);
            },
        );
  }

  private getUsersWithHistoricReports(): void {
    this.isLoading++;
    this.reportService.getHistoricalUserWithReports().pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Users with historical reports fetched successfully');
              this.usersWithHistoricReports = result.data;
            },
        );
  }

  private dmPropCreadaExport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.reportService.dmPropCreadaExport().subscribe(
        result => {
          this.toastr.success(result.message ?? 'DM Prop Creada Exported successfully');
        },
    );
  }
}
