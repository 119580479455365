import { EnvironmentInterface } from './EnvironmentInterface';

export const environment: EnvironmentInterface = {
  APP_NAME:              'Unifye',
  APP_ENV:               'production',
  production:            true,
  devMode:               false,
  API_DOMAIN:            'api.unifye.com',
  API_SECURE_PROTOCOL:   true,
  API_URL:               'https://api.unifye.com',
  STORAGE_URL:           'https://api.unifye.com/storage',
  APP_URL:               'https://app.unifye.com',
  CLIENT_APP_URL:        'https://clientes.unifye.com',
  REFRESH_NOTIFICATIONS: true,
  GENERAL_SITE_URL:      'www.unifye.com',
  DISTRIBUTION_USER_IDS: [27, 36, 2403, 2497, 751562, 751600, 752733, 761789, 1, 773843, 751562, 761789, 768371, 752733, 751601],
  BLOCK_CASES_STATUS_IDS: [335,507],
  ALLOW_CASES_ROLES_IDS: [5,7],
  ALLOW_CASES_DEPARTMENTS_IDS: [13],
  MANAGER_USER_IDS: [747112, 747116, 747113, 756673, 752389],

  COMPANY_NAME:        'Unifye',
  COMPANY_NAME_LEGAL:  'Unifye Iberia SL.',
  COMPANY_ID_CIF:      'B56253362',
  COMPANY_ADDRESS:     'Calle Diego de León, 47 (Madrid), CP 28006',
  COMPANY_BANK_NAME:   'BBVA',
  COMPANY_BANK_IBAN:   'ES10 0182 0192 7602 0165 9379',
  COMPANY_PHONE_LEGAL: '910 053 393',
  VAT_PERCENTAGE:       21,

  EMAIL_INFO:                 'info@unifye.com',
  EMAIL_LEGAL:                'legal@unifye.com',
  EMAIL_ADMINISTRATION:       'administracion@unifye.com',
  EMAIL_CANCELLATIONS:        'cancelaciones@unifye.com',
  EMAIL_CONTRACT_CONTACT:     'contract@unifye.com',
  EMAIL_SUPPORTING_DOCUMENTS: 'justificantes@unifye.com',
  EMAIL_GDPR:                 'dataprotectionofficer@unifye.com',

  GOOGLE_ANALYTICS_CODE:    'G-NPW7ZJLJHP',
  GOOGLE_ANALYTICS_ENABLED: false,
};
