<div class="col-12" *ngIf="authUser.role?.slug === 'creditor'">
  <!-- mat tab -->
  <div style="position:relative">
    <mat-tab-group (selectedTabChange)="yourFn($event)" #tabs  class="hides-tabs">
      <mat-tab [label]="'CASES.single.pending' | translate"></mat-tab>
      <mat-tab [label]="'CASES.single.accepted' | translate"></mat-tab>
    </mat-tab-group>
  </div>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!-- ID Number -->
      <ng-container matColumnDef="id_card">
        <th mat-header-cell *matHeaderCellDef>{{ 'USERS.model.id_card' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.case.client.id_card }}</td>
      </ng-container>
      <!-- Last Name -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.case.client.last_name }}</td>
      </ng-container>
      <!-- Amount of creditors -->
      <ng-container matColumnDef="no_of_creditors">
        <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            {{creditorCount(element.case.creditors)}}
        </td>
      </ng-container>
      <!-- Debt with Creditor -->
      <ng-container matColumnDef="debt_with_cred">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASES.creditor_debt_amount' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{ element.debt_amount | currency }}</td>
      </ng-container>
      <!-- Debt Amount -->
      <ng-container matColumnDef="debt_amount">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.total-debt-amount" | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{ element.case.sum_total_debt_amount | currency }}</td>
      </ng-container>
      <!-- Proposed Settlement -->
      <ng-container matColumnDef="proposed_settlement">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASES.target_settlement_amount' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">
          {{calculateProposedSettlement(element) | currency}}
        </td>
      </ng-container>
      <!-- Negotiated Settlement -->
      <ng-container matColumnDef="negotiated_amount">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASES.proposed_settlement' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">
          {{ (element.negotiated_amount === element.debt_amount) ? null : element.negotiated_amount | currency}}
        </td>
      </ng-container>
      <!-- Final Settlement -->
      <ng-container matColumnDef="final_settlement">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASES.enter_settlement_amount' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">
          <input type="number" value="" [id]="element.id" />
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex" class="pl-2" *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
            <div>
              <button mat-stroked-button class="btn-small ml-2" color="primary" (click)="acceptSettlementAmount(element)">{{ (element.case_creditor_status_id === 50 ? 'SHARED.apply' : 'SHARED.save') | translate }}</button>
              <button (click)="downloadMandateFile(element)" [disabled]="!element.case.contracts[0]?.mandate_location" class="btn-small ml-2" mat-raised-button color="primary">Mandato</button>
              <button (click)="openDniDialog(element)" [disabled]="!checkForDNIFiles(element)" class="btn-small ml-2" mat-raised-button color="primary">DNI</button>
            </div>
          </div>
        </td>
        <!-- Negotiated Settlement -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{ 'CASES.details.status' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">
            <!-- [20/1/25, 13:20:36] DF - Mark: Prestamo Active = “Paid”, Prestamo Rechazado or Negociacion DM = “Declined”, anything else = “Pending”
            [20/1/25, 13:21:15] DF - Mark: Prestamo Rechazado or Negociacion DM = “Declined” (this means if the case ever goes into this status, its “Declined” even if the status then moves on to something else -->
            <div>
                <span class="badge badge-primary" *ngIf="element.case.status_id == 364">Pagado</span>
                <span class="badge badge-primary" *ngIf="[393, 500].includes(element.case.status_id)">Rechazado</span>
                <span class="badge badge-primary" *ngIf="![364, 393, 500].includes(element.case.status_id)">Pendiente</span>
            </div>
          </td>
        </ng-container>
      </ng-container>
    </table>


    <!-- Loader -->
    <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- No Data -->
    <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
      <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
    </div>
    <!-- Pagination -->
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
    </mat-paginator>
  </div>