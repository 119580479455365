import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})

export class StatsService extends MainBaseApiService {
    public getStats(uuid: string): Observable<LaravelResourceResponse> {
        return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/stats/${uuid}`)
            .pipe(catchError(this.handleError));
    }
}