<app-case-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleFilters($event)"
                       (parentFilter)="applyFilters()" (parentNewCase)="newCasePrefill()" (parentClearFilters)="clearFilters()"
                       [onlyRelated]="onlyRelated" [type]="filterType">
</app-case-list-filters>

<!-- Bulk Notifications Form -->
<div class="d-flex justify-content-between" *ngIf="filterType !== 'legal'">
  <form *ngIf="bulkActionForm && (authUser.role_id === 1 || authUser.role_id === 5)" [formGroup]="bulkActionForm"
        #ngBulkForm
        (ngSubmit)="sendNotifications(bulkActionForm)">
    <mat-form-field appearance="standard">
      <mat-label>{{ "CASES.list.notifications.label" | translate }}</mat-label>
      <mat-select id="action" formControlName="action">
        <mat-option value="">{{ "CASES.list.notifications.options.none" | translate }}</mat-option>
        <mat-option value="sms">{{ "CASES.list.notifications.options.sms" | translate }}</mat-option>
        <mat-option value="email">{{ "CASES.list.notifications.options.email" | translate }}</mat-option>
        <mat-option value="all">{{ "CASES.list.notifications.options.all" | translate }}</mat-option>
      </mat-select>
      <div *ngIf="bulkActionForm.get('action').errors && ngBulkForm.submitted">
        <mat-error [hidden]="!bulkActionForm.get('action').hasError('required')">
          {{ "SHARED.field-required" | translate }}
        </mat-error>
      </div>
    </mat-form-field>
    <button mat-raised-button class="ml-3" type="submit" color="primary"
            [disabled]="bulkActionForm.invalid || selection.selected.length === 0">
      {{ 'SHARED.send' | translate }}
    </button>
  </form>
</div>
<!--Apply button on legal page-->
<div class="text-right mb-3" *ngIf="filterType === 'legal'">
  <button mat-raised-button color="primary" (click)="applyFilters()">
    {{ 'SHARED.apply_filters' | translate }}
  </button>
</div>

<!--If all are selected and there is more to select on another page -->
<div *ngIf="filtersReady && caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
  <div class="col-12">
    <div class="global-select-all">
      <p class="m-0" *ngIf="!caseListFilter.select_all">
        Selected <b>{{ selection.selected.length }}</b> cases on this page.
        <button mat-button color="primary" (click)="globalSelectAll($event)">
          Select all {{ totalResults | number }} cases
        </button>
      </p>
      <p class="m-0" *ngIf="caseListFilter.select_all">
        All <b>{{ totalResults | number }}</b> cases selected.
        <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div [class]="this.isUserCreditorNegotiator ? 'col-md-8' : 'col-12'" *ngIf="authUser.role?.slug !== 'creditor'">
    <div *ngIf="filtersReady && filterType !== 'customer_contact'; else contactTable" class="mat-elevation-z8">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="authUser.role?.slug === 'creditor' ? null : ['/cases', row.id]"></tr>
        <!-- Selection -->
        <ng-container matColumnDef="select">
          <!-- Select All On Page -->
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null" *ngIf="authUser.role?.slug !== 'creditor'"
                          [checked]="selection.hasValue() && allRowsSelected()"
                          [indeterminate]="selection.hasValue() && !allRowsSelected()">
            </mat-checkbox>
            <span *ngIf="authUser.role?.slug === 'creditor'">Aceptar Plan</span>
          </th>
          <!-- Select Row -->
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                          (change)="toggleRow($event, row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- accept plan -->
        <ng-container matColumnDef="accept">
          <!-- Select All On Page -->
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="authUser.role?.slug === 'creditor'">Aceptar Plan</span>
          </th>
          <!-- Select Row -->
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="creditorAcceptPlan.isSelected(row)"
                          (change)="toggleDmRow($event, row, 'accept')">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- reject plan -->
        <ng-container matColumnDef="reject">
          <!-- Select All On Page -->
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="authUser.role?.slug === 'creditor'">Rechazar Plan</span>
          </th>
          <!-- Select Row -->
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="creditorRejectPlan.isSelected(row)"
                          (change)="toggleDmRow($event, row, 'reject')">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Ref Number -->
        <ng-container matColumnDef="ref_number">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
              {{ element.ref_number }}</a></td>
        </ng-container>
        <!-- First Name -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
        </ng-container>
        <!-- Last Name -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
        </ng-container>
        <!-- ID Number -->
        <ng-container matColumnDef="id_card">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.id_card' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.client.id_card }}</td>
        </ng-container>
        <!-- Product Name -->
        <ng-container matColumnDef="product_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.product?.name }}</td>
        </ng-container>
        <!-- Package Name -->
        <ng-container matColumnDef="packager_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PACKAGER.model_name.singular" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.packager?.name_es }}</td>
        </ng-container>
        <!-- Status and Payment Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge" [ngClass]="calculateDaysSinceLastContact(element.case_status_logs) > 14 ? ' badge-primary' : ' badge-primary-inverted'">
                {{ element.status?.name }}</span>
            </div>
            <div>
              <span class="badge badge-info" *ngIf="element.payment_status">{{ element.payment_status?.name }}</span>
            </div>
            <div *ngIf="element.case_invoice_status_id">
              <span class="badge badge-danger" *ngIf="this.authUser?.id === 1 || this.authUser?.id === 22 ||
              this.authUser?.id === 27 || this.authUser?.id === 34 || this.authUser?.id === 36 || this.authUser?.id === 2497">
                {{ element.case_invoice_status?.name }}
              </span>
            </div>
            <!-- Docs Option -->
            <div *ngIf="element.doc_status?.status_id === 405">
              <span class="badge badge-danger">
                {{ element.doc_status?.name }}
              </span>
            </div>
          </td>
        </ng-container>
        <!-- Time in Status -->
        <ng-container matColumnDef="entered_at_status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.call-status' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.call_status">
              <span class="badge {{element.call_status?.name === 'Current' ? 'badge-info' : 'badge-danger'}}">{{ element.call_status?.name }}</span>
            </div>
            <!-- <div>
              <p class="m-0">{{ element.latest_case_status_log?.created_at | dateDifference}}</p>
            </div> -->
          </td>
        </ng-container>
        <!-- Distribution -->
        <ng-container matColumnDef="in_distribution">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'USERS.distribution' | translate}}</th>
          <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element?.distribution.length > 0" style="color: rgb(0, 80, 0)" class="mat-icon">check</mat-icon>
              <!-- <mat-icon *ngIf="element?.distribution.length == 0" style="color: rgb(98, 0, 0)" class="mat-icon">clear</mat-icon> -->
          </td>
        </ng-container>
        <!-- Days in DM Sent Creditor -->
        <ng-container matColumnDef="dm_sent_creditor">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.dm_sent_creditor' | translate }}</th>
          <td mat-cell *matCellDef="let element">
              <div [ngClass]="getDaysInDmSentCreditor(element)"></div>
          </td>
        </ng-container>
        <!-- Microlenders -->
        <ng-container matColumnDef="microlenders">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.microlenders' | translate}}</th>
          <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.microlenders" style="color: rgb(0, 80, 0)" class="mat-icon">check</mat-icon>
              <!-- <mat-icon *ngIf="!element.microlenders" style="color: rgb(98, 0, 0)" class="mat-icon">clear</mat-icon> -->
          </td>
        </ng-container>
        <!-- Reduction -->
        <ng-container matColumnDef="fds_reduction">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{(+element.debt_reduction).toFixed(2)}} %</p>
            </div>
          </td>
        </ng-container>
        <!-- Reduction -->
        <ng-container matColumnDef="reduction">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_accepted" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{element.dm_accepted_percent}} %</p>
            </div>
          </td>
        </ng-container>
        <!-- Number of Creditors -->
        <ng-container matColumnDef="no_creditors">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "NAVBAR.creditors" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{getUnsecuredCreditors(element)}}</p>
            </div>
          </td>
        </ng-container>
        <!-- Packager Status -->
        <ng-container matColumnDef="packager_status" *ngIf="!authUser.packager.master">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.packager-status' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge badge-primary">{{ element.packager_status?.name }}</span>
            </div>
          </td>
        </ng-container>
        <!-- Created At -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.created_at" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <p class="m-0">{{ element.created_at | toDateObject | date:'dd/MM/yy' }}</p>
            <p class="m-0">{{ element.created_at | toDateObject | date:'HH:mm' }}</p>
          </td>
        </ng-container>
        <!-- Affiliate -->
        <ng-container matColumnDef="affiliate">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.affiliates" | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ element.affiliate[0]?.name }}</td>
        </ng-container>
        <!-- Disposable Income -->
        <ng-container matColumnDef="disposable_income">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Disposable Income</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.total_disposable_income) : element.total_disposable_income | currency }}</td>
        </ng-container>
        <!-- Amount Paid -->
        <ng-container matColumnDef="amount_paid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.amount_paid) : element.amount_paid | currency }}</td>
        </ng-container>
        <!-- Debt Amount -->
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.debt-amount" | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.sum_total_debt_amount) : element.sum_total_debt_amount | currency }}</td>
        </ng-container>
        <!-- Amount Paid -->
        <ng-container matColumnDef="debt_with_cred">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.creditor_debt' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ debtWithSelectedCreditors(element.creditors) | currency }}</td>
        </ng-container>
        <!-- Amount of creditors -->
        <ng-container matColumnDef="no_of_creditors">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
          <td mat-cell *matCellDef="let element">
              {{creditorCount(element.creditors)}}
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex" class="pl-2" *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
              <div *ngIf="authUser.role?.slug !== 'creditor'">
                <mat-icon class="mat-icon-size pr-5" *ngIf="!this.isUserCreditorNegotiator" (click)="clickToCall(element.client.phone, $event)" color="primary">phone</mat-icon>
                <mat-icon class="mat-icon-size pr-5" *ngIf="!this.isUserCreditorNegotiator" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'edit', 'general']" color="primary">edit</mat-icon>
                <mat-icon class="mat-icon-size pr-5" (click)="openDeleteDialog(element, $event)" color="primary" *ngIf="[1, 5, 7, 26, 27, 34, 36, 751600, 751601, 752733].includes(authUser.id)">delete</mat-icon>
                <mat-icon class="mat-icon-size pr-5" (click)="showCreditorsForCase(element, $event)" color="primary" *ngIf="this.isUserCreditorNegotiator" >visibility</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
      </table>


      <!-- Loader -->
      <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- No Data -->
      <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
    
  </div>
  <div *ngIf="this.isUserCreditorNegotiator" class="col-md-4">
    <div class="mat-elevation-z8 mb-5">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="creditorDataSource">
        <tr mat-header-row *matHeaderRowDef="negotiatorCreditorCols"></tr>
        <!--  [routerLink]="['/cases', row.id, 'creditors']" -->
        <tr mat-row *matRowDef="let row; columns: negotiatorCreditorCols;"></tr>
        <!-- Creditor -->
        <ng-container matColumnDef="creditor">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Creditor Name</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{element.name}}</p>
            </div>
          </td>
        </ng-container>
        <!-- Creditor Status -->
        <ng-container matColumnDef="creditor_status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASE_CREDITOR.model.status' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{"CASE_CREDITOR.model.response_received.options." + element.pivot.response_received | translate }}</p>
            </div>
          </td>
        </ng-container>
        <!-- Debt Amount -->
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASE_CREDITOR.model.debt_amount' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.pivot.debt_amount) : element.pivot.debt_amount | currency }}</p>
            </div>
          </td>
        </ng-container>
        <!-- Negotiated Amount -->
        <ng-container matColumnDef="negotiated_amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASE_CREDITOR.model.negotiated_amount' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.pivot.negotiated_amount) : element.pivot.negotiated_amount | currency }}</p>
            </div>
          </td>
        </ng-container>
        <!-- Percent Reduction -->
        <ng-container matColumnDef="percent_reduction">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>% {{'CASES.reduction' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{(((element.pivot.debt_amount - element.pivot.negotiated_amount) / element.pivot.debt_amount) * 100).toFixed(2)}}%</p>
            </div>
          </td>
        </ng-container>
      </table>
    </div>
  </div>
  <!-- CREDITOR VIEW -->
  <div class="col-12" *ngIf="authUser.role?.slug === 'creditor'" (window:resize)="onResize($event)">
    <!-- mat tab -->
    <div style="position:relative">
      <button mat-raised-button color="primary" class="ml-2" id="savePlanBtn" (click)="acceptRejectDialog()" style="display: none;">
        {{ 'SHARED.save' | translate }}
      </button>
      <mat-tab-group (selectedTabChange)="yourFn($event)" #tabs  class="hides-tabs">
        <mat-tab [label]="'CASES.single.pending' | translate"></mat-tab>
        <mat-tab [label]="'CASES.single.accepted' | translate"></mat-tab>
        <mat-tab [label]="'CASES.single.declined' | translate"></mat-tab>
      </mat-tab-group>
    </div>
    
    <div *ngIf="filtersReady && filterType !== 'customer_contact'; else contactTable" class="mat-elevation-z8">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- accept plan -->
        <ng-container matColumnDef="accept">
          <!-- Select All On Page -->
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="authUser.role?.slug === 'creditor'" id="accept_column">{{'SHARED.accept' | translate}}</span>
          </th>
          <!-- Select Row -->
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" class="test" color="primary" [checked]="creditorAcceptPlan.isSelected(row)"
                          (change)="toggleDmRow($event, row, 'accept')">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- reject plan -->
        <ng-container matColumnDef="reject">
          <!-- Select All On Page -->
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="authUser.role?.slug === 'creditor'">{{'SHARED.decline' | translate}}</span>
          </th>
          <!-- Select Row -->
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="creditorRejectPlan.isSelected(row)"
                          (change)="toggleDmRow($event, row, 'reject')">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Ref Number -->
        <ng-container matColumnDef="ref_number">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
              {{ element.ref_number }}</a></td>
        </ng-container>
        <!-- First Name -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.case.client.first_name }} </td>
        </ng-container>
        <!-- Last Name -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.case.client.last_name }}</td>
        </ng-container>
        <!-- ID Number -->
        <ng-container matColumnDef="id_card">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.id_card' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.case.client.id_card }}</td>
        </ng-container>
        <!-- Microlenders -->
        <ng-container matColumnDef="microlenders">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.microlenders' | translate}}</th>
          <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.case.microlenders" style="color: rgb(0, 80, 0)" class="mat-icon">check</mat-icon>
              <!-- <mat-icon *ngIf="!element.microlenders" style="color: rgb(98, 0, 0)" class="mat-icon">clear</mat-icon> -->
          </td>
        </ng-container>
        <!-- Number of Creditors -->
        <ng-container matColumnDef="no_creditors">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "NAVBAR.creditors" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="m-0">{{getUnsecuredCreditors(element)}}</p>
            </div>
          </td>
        </ng-container>
        <!-- Amount Paid -->
        <ng-container matColumnDef="amount_paid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.case.amount_paid) : element.amount_paid | currency }}</td>
        </ng-container>
        <!-- Debt Amount -->
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.debt-amount" | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.case.sum_total_debt_amount) : element.case.sum_total_debt_amount | currency }}</td>
        </ng-container>
        <!-- Amount Paid -->
        <ng-container matColumnDef="debt_with_cred">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.creditor_debt' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pr-2">{{ element.debt_amount | currency }}</td>
        </ng-container>
        <!-- Amount of creditors -->
        <ng-container matColumnDef="no_of_creditors">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
          <td mat-cell *matCellDef="let element">
              {{creditorCount(element.case.creditors)}}
          </td>
        </ng-container>
        <!-- Monthly Payment -->
        <ng-container matColumnDef="monthly_payment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.model.monthly_payments' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.offer_to_creditor | currency }}</td>
        </ng-container>
        <!-- Final Payment -->
        <ng-container matColumnDef="final_payment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CREDITORS.final_payment' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.terms <= 1 ? '0,00 €' : element.final_payment | currency }}</td>
        </ng-container>
        <!-- Terms -->
        <ng-container matColumnDef="terms">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.model.term' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.terms }}</td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex" class="pl-2" *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
              <div>
                <button (click)="downloadMandateFile(element)" [disabled]="!element.case.contracts[0]?.mandate_location" class="btn-small ml-2" mat-raised-button color="primary">Mandato</button>
                <button (click)="openDniDialog(element)" [disabled]="!checkForDNIFiles(element)" class="btn-small ml-2" mat-raised-button color="primary">DNI</button>
              </div>
            </div>
          </td>
        </ng-container>
      </table>


      <!-- Loader -->
      <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- No Data -->
      <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
      
</div>
<ng-template #contactTable>
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id]"></tr>
    <!-- Selection -->
    <ng-container matColumnDef="select">
      <!-- Select All On Page -->
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                      [checked]="selection.hasValue() && allRowsSelected()"
                      [indeterminate]="selection.hasValue() && !allRowsSelected()">
        </mat-checkbox>
      </th>
      <!-- Select Row -->
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                      (change)="toggleRow($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Ref Number -->
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
      <td mat-cell *matCellDef="let element"><a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
        {{ element.ref_number }}</a> </td>
    </ng-container>
    <!-- First Name -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
    </ng-container>
    <!-- Last Name -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.last_name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
    </ng-container>
    <!-- Product Group Slug -->
    <ng-container matColumnDef="product_group_slug">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.group_slug" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.product?.group_name_es | uppercase }}</td>
    </ng-container>
    <!-- Case Status -->
    <ng-container matColumnDef="case_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-primary">{{ element.status?.name }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Payment Status -->
    <ng-container matColumnDef="payment_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CASES.single.general.status_editor.payment_status' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="badge badge-info">{{ element?.payment_status?.name }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Time in Status -->
    <ng-container matColumnDef="entered_at_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.time-in-status' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p class="m-0">{{element.latest_case_status_log?.created_at | dateDifference}}</p>
        </div>
      </td>
    </ng-container>
    <!-- Time since docs uploaded -->
    <ng-container matColumnDef="time_since_doc_uploaded">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.time_since_doc_uploaded' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <p class="m-0">{{ element.oldest_case_pending_document?.created_at | dateDifference}}</p>
        </div>
      </td>
    </ng-container>
    <!-- Disposable Income -->
    <ng-container matColumnDef="disposable_income">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ (this.isUserCreditorNegotiator) ? math.trunc(element.expense.offer_to_creditors_total) : element.expense.offer_to_creditors_total | currency }}</td>
    </ng-container>
    <!-- Amount Paid -->
    <ng-container matColumnDef="amount_paid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.amount-paid" | translate }}</th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{ element.amount_paid | currency }}</td>
    </ng-container>
    <!-- Last Call Contact-->
    <ng-container matColumnDef="last_call_contact">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-call-contact" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.history_call}}</td>
    </ng-container>
    <!-- Last Successful Call Contact -->
    <ng-container matColumnDef="last_successful_call_contact">
      <th mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ "CASES.single.last-successful-call-contact" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.history_successful_call}}</td>
    </ng-container>
    <!-- Agent -->
    <ng-container matColumnDef="agent">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.agent" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element?.agent}}</td>
    </ng-container>
    <!-- Call duration -->
    <ng-container matColumnDef="call_duration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASES.single.general.relation_list.notification_list.call.duration" | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="pr-2">{{element.call_duration}}</td>
    </ng-container>
    <!-- Last WhatsApp Contact -->
    <ng-container matColumnDef="last_whatsapp_contact">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-whatsapp-contact" | translate }}</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex" class="pl-2">
          <a class="pr-3" (click)="$event.stopPropagation()" target="_blank" style="font-weight: 400"
             [routerLink]="['/cases', element.id, 'general']">{{ "SHARED.view" | translate }}</a>
          <a class="pr-3" (click)="$event.stopPropagation()"
             [routerLink]="['/cases', element.id, 'edit', 'general']">{{ "SHARED.edit" | translate }}</a>
          <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{ "SHARED.delete" | translate }}</a>
        </div>
      </td>
    </ng-container>
  </table>


  <!-- Loader -->
  <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- No Data -->
  <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
  </div>
  <!-- Pagination -->
  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
  </mat-paginator>
</ng-template>
<!-- Footer Actions -->
<div class="mt-2 pb-3 pl-1"
     *ngIf="authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 || isUserCreditorNegotiator
      || authUser.id === 678731 || authUser.id === 2597 || authUser.id === 704999">
  <!-- Export Cases -->
  <button *ngIf="filterType !== 'customer_contact' && (authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 ||
          authUser.role_id === 26 || authUser.id === 644922 || authUser.id === 661765 || authUser.id === 664434 || authUser.id === 678731
          || authUser.id === 2597 || authUser.id === 704999 || authUser.id === 406931 || authUser.id === 747127|| authUser.id === 751527 || isUserCreditorNegotiator)"
          mat-raised-button color="primary" class="mr-3" [hidden]="isManager" [hidden]="true"
          [matMenuTriggerFor]="menuSecured" [disabled]="selection.selected.length === 0">
    {{ "CASES.list.export.button-name" | translate }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menuSecured="matMenu">
    <a mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter)">
      {{ "CASES.list.export.button-name" | translate }}
    </a>
    <a [hidden]="authUser.id === 678731" mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter, 1)" [hidden]="true">
      {{ "CASES.list.export.button-name-marketing" | translate }}
    </a>
    <a [hidden]="authUser.id === 678731" mat-menu-item class="dropdown-item" (click)="openGCLIDModal($event, caseListFilter)" [hidden]="true">
      {{'CASES.list.export.button-name-gclid' | translate}}
    </a>
  </mat-menu>
  <!-- Export Customer Contact-->
  <button
    *ngIf="authUser.role_id === 1 || authUser.role_id === 2 || authUser.role_id === 5 || authUser.role_id === 7"
    mat-raised-button color="primary"
    (click)="exportRecordsCustomerContact(caseListFilter)" class="mr-3">
    {{ "CASES.list.export.button-name" | translate }}
  </button>
  <button *ngIf="isManager" mat-raised-button color="primary" (click)="exportRecordsCustomerContact(caseListFilter)" class="mr-3" [hidden]="true">
    {{ "CASES.list.export.button-name" | translate }}
  </button>
  <!-- Export Last Actions -->
  <button
    *ngIf="filterType !== 'legal' && filterType !== 'customer_contact' && authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7"
    mat-raised-button color="primary" [hidden]="isManager"
    (click)="exportLastActionRecords(caseListFilter)" [disabled]="selection.selected.length === 0" class="mr-3">
    {{ "CASES.list.export.button-name-last-action" | translate }}
  </button>
  <ng-container *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
    <!-- Push to Dialer -->
    <button mat-raised-button color="primary" (click)="dialerListIdSelector()"
            *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 238987 || authUser.id === 613764
            || authUser.id === 34 || authUser.id === 336521 || authUser.id === 1 || authUser.id === 747121 || authUser.id === 751600"
            [disabled]="filterType !== 'customer_contact' && selection.selected.length === 0">
      {{ "CASES.list.push_to_dialer.button" | translate }}
    </button>
    <!-- Assign Distribution -->
    <button mat-raised-button color="primary" class="mr-3" (click)="moveToDistribution(caseListFilter)" [hidden]="false"
            [disabled]="selection.selected.length === 0" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
      {{ "CASES.list.assign_distribution.button" | translate }}
    </button>
    <!-- Bulk Updates -->
    <button mat-raised-button color="primary" class="mr-3"
            [matMenuTriggerFor]="bulkActionsMenu" [disabled]="selection.selected.length === 0">
      {{ "CASES.list.bulk_actions.button" | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #bulkActionsMenu="matMenu">
      <a *ngIf="authUser.id === 752733 || authUser.id === 27 || authUser.id === 787448 || authUser.id === 22" mat-menu-item class="dropdown-item"
         (click)="openChangeModal('status', caseListFilter)">
        {{ "CASES.list.bulk_actions.change_case_status" | translate }}
      </a>
      <a *ngIf="this.authUser?.id === 1 || this.authUser?.id === 22 || this.authUser?.id === 27 ||
       this.authUser?.id === 34 || this.authUser?.id === 36 || this.authUser?.id === 2497"
         mat-menu-item class="dropdown-item" (click)="openChangeModal('case_invoice_status', caseListFilter)">
        {{ "CASES.list.bulk_actions.change_case_invoice_status" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="openChangeModal('department_assignments', caseListFilter)"
         *ngIf="authUser.id === 752733 || authUser.id === 27 || authUser.id === 787448 || authUser.id === 22">
        {{ "CASES.list.bulk_actions.change_department_status" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="openChangeModal('product_type', caseListFilter)"
         *ngIf="authUser.id === 752733 || authUser.id === 27">
        {{'CASES.list.bulk_actions.change_product_type' | translate}}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="downloadDocuments()"
         *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
        {{'CASES.list.bulk_actions.download_documents' | translate}}
      </a>
    </mat-menu>
  </ng-container>
</div>
