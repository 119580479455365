import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Proposal } from '../../../../../../../../_base-shared/models/CaseDocument/Proposal';
import { FinancialOverview } from '../../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { environment } from '../../../../../../environments/environment';
import { FinancialOverviewService } from '../../../../payment/financial-overview.service';
import { CaseService } from '../../../case.service';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

// Validator function for creditor_term
export function creditorTermValidator(clientProposalControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const creditorTerm = control.value;
    const clientProposal = clientProposalControl?.value;
    // Check if creditorTerm exceeds clientProposal
    return creditorTerm > clientProposal
      ? { creditorTermInvalid: true }
      : null;
  };
}


@Component({
  selector:    'app-proposal-modal',
  templateUrl: './proposal-modal.component.html',
  styleUrls:   ['./proposal-modal.component.scss'],
  animations:  [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1})),
          ],
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0})),
          ],
        ),
      ],
    ),
  ],
})
export class ProposalModalComponent implements OnInit {
  public case: Case;
  public form: UntypedFormGroup;
  public formActive = false;
  public isAutomationPaused = false;
  public isLoading  = 0;
  public storageUrl = environment.STORAGE_URL + '/';

  public financialOverview: FinancialOverview;
  public disposableIncome = 0;
  public unsecuredDebt    = 0;
  public unsecuredDMDebt    = 0;
  public showStepPayment  = false;

  public fixedDistributionFee: number = 0;
  public percentageDistributionFee: number = 0;
  public distributionFee: number = 0;
  public actualDisposable: number = 0;
  public creditors: Array<Creditor> = [];
  public creditorHasOffer: boolean = false;
  public unallocated: number = 0;
  public allocated: number = 0;
  public isLocked: boolean = true;
  public authUser: User;
  todayDate:Date = new Date();
  public allowedUsers = [27, 36, 752733, 751600, 768371, 751601, 761789, 751562];
  public showCustomFields: boolean = false;

  public disposableOptions: Array<{value: { index: number, amount: number }, label: string }> = [];
  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private dialogRef: MatDialogRef<ProposalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(MAT_DIALOG_DATA) public disableProposalButtons: boolean,
              private translateService: TranslateService,
              private toast: ToastrService,
              private financialOverviewService: FinancialOverviewService,
              private configService: MiscConfigService,
              public globalEventsService: MainGlobalEventService,
              private cdr: ChangeDetectorRef) {
  }

  /*ngOnDestroy(): void {
    console.log("DESTROY!");
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }*/

  ngOnInit(): void {
    this.getDistributionFees();
    this.case               = this.data.case;
    this.disableProposalButtons = this.data.disableProposalButtons;
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    //if (this.authUser) {
    //  this.isManager = environment.MANAGER_USER_IDS.includes(this.authUser.id);
    //}
    
    const requiredRelations = [
      'product',
      'terms',
      'proposal',
      'expense',
    ];
    const loadRelations     = [];
    requiredRelations.forEach(relation => {
      if (!this.case[relation]) {
        loadRelations.push(relation);
      }
    });
    loadRelations.push('creditors');

    this.isLoading++;
    this.financialOverviewService.fetchLatest(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.financialOverview = result.data;
      this.isLoading++;
      this.caseService.get(this.case.id, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(res => {
        loadRelations.forEach(relation => {
          this.case[relation] = res.data[relation];
        });
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.type === 'unsecured');
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.product_id !== 18);
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.case_creditor_status_id !== 11);

        this.disposableOptions = [
          {value: { index: 0, amount: +this.case.target_monthly_payment }, label: Number(this.case.target_monthly_payment).toFixed(2) +'€ - '+ this.translateService.instant('CASES.financial_overview.table.target_monthly_payment')},
          {value:  { index: 1, amount: this.financialOverview.income - this.financialOverview.expenses }, label: (+this.financialOverview.income - +this.financialOverview.expenses).toFixed(2) + '€ - ' + this.translateService.instant('CASES.financial_overview.table.disposable_income')},
        ];
        if (this.case.proposal){
          const thirdOptionValue = (this.case.proposal?.offer_to_creditors || 0) + (this.case.expense?.distribution_fee || 0);
          if (thirdOptionValue > 0) {
            this.disposableOptions.push({
              value: { index: 2, amount: this.case.proposal.offer_to_creditors + this.case.expense?.distribution_fee },
              label: thirdOptionValue.toFixed(2) + '€ - ' + this.translateService.instant('saved in last proposal')
            });
          }
        }
        this.disposableOptions.push({
          value: { index: 4, amount: 0 },
          label: this.translateService.instant('custom')
        });
        
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.case_creditor_status_id !== 14 && creditor.pivot.case_creditor_status_id !== 15 && creditor.pivot.case_creditor_status_id !== 17 );

        if(this.case) {
            this.disposableIncome = +(this.case.target_monthly_payment ?? (this.financialOverview.income - this.financialOverview.expenses));
        }
        // sum the unsecured debt from the creditors
        this.case.creditors.forEach(creditor => {
            this.unsecuredDMDebt += +creditor.pivot.debt_amount;
            this.unsecuredDebt += +creditor.pivot.initial_balance;
        });

        this.buildForm();

        if (this.case.proposal && this.case.proposal.created_at) {
          
          //filter by date to make sure it counts only DM amounts
          //pros, show everything even if wrong
          const createdAt = new Date(this.case.proposal.created_at);
          const comparisonDate = new Date(2024, 10, 23);
          comparisonDate.setHours(0, 0, 0, 0); 
          
          const hasValidProposal = createdAt > comparisonDate;

          //DAM refactor to a function, filters if DM calculation is correct
          //discards if something was saved and incorrect...
          /*let hasValidProposal = false;
          if (this.case.creditors?.length > 0) {
            hasValidProposal = this.case.creditors.every((creditor) => {
              console.log("TERMS CHECK");
              console.log(creditor.pivot);
              return creditor.pivot.debt_amount == (creditor.pivot.term * creditor.pivot.offer_to_creditor);
            });
          }*/
        
          if (this.form && this.form.get('actual_disposable')?.value && !hasValidProposal) {
            this.calculateDistributionFee();
            this.calculateDisposableIncome();
            this.calculateDividend();
            this.calculateUnallocated();
            //this.calculateAllocatedUnifyePlan();
          }
        
          this.isLocked = true;
          if (hasValidProposal) {
            this.form.get('client_proposal')?.patchValue(this.case.proposal.term, {
              emitEvent: false,
              onlySelf: true,
            });
            
            this.changeDisposableIncomeSelect(); //add loaded to the select (amount)
          }else{
            this.calculateProposalTerm();  
          }
        } else {
          this.isLocked = false;
          if (this.form && this.form.get('actual_disposable')?.value) {
            this.calculateDistributionFee();
            this.calculateDisposableIncome();
            this.calculateDividend();
            this.calculateProposalTerm();
            //this.calculateClientFinalPayment();
            this.calculateUnallocated();
          }
        }

        //this.calculateClientFinalPayment();
        this.form.get('disposable_income').valueChanges.subscribe(() => {
          if (!this.formActive) {
            return;
          }
          if (this.form.get('disposable_income').value.index == 2){
            this.refreshFormToDatabaseValues(this.case.creditors);
          }else if (this.form.get('disposable_income').value.index == 4){
            this.showCustomFields = true;
          }else{
            this.calculateDistributionFee();
            this.calculateDisposableIncome();
            this.calculateProposalTerm();
            //this.calculateClientFinalPayment();
          }
        });
        this.form.get('actual_disposable').valueChanges.subscribe(() => {
          if (!this.formActive || this.isAutomationPaused) {
            return;
          }
          this.creditorHasOffer = false;
          this.calculateDividend();
          this.calculateProposalTerm();
        });
        this.form.get('client_proposal').valueChanges.subscribe(() => {
          if (!this.formActive) {
            return;
          }
          this.calculateDividend();
        });
        this.form.get('term').valueChanges.subscribe(() => {
          if (!this.formActive || this.isAutomationPaused) {
            return;
          }
          this.calculateDividend();
        });
        this.form.get('debt_level').valueChanges.subscribe(() =>{
          if (!this.formActive) {
            return;
          }    
          this.calculateDividend();    
        });
        this.form.get('step_offer').valueChanges.subscribe(() =>{
          if (!this.formActive) {
            return;
          }
          this.calculateDividend();
        });
        this.form.get('step_period').valueChanges.subscribe(() => {
          if (!this.formActive) {
            return;
          }
          this.calculateDividend()
        });
        this.form.get('distribution_fee').valueChanges.subscribe(value => {
          if (!this.formActive || this.isAutomationPaused) {
            return;
          }  
          this.calculateDisposableIncome();
          this.calculateProposalTerm();
        });
        if (this.case.product.slug === 'cajaplus') {
          this.form.get('legal_fees')
            .valueChanges
            .subscribe((value: number) => {
              if (!this.formActive) {
                return;
              }
              this.calculateOfferToCreditorsV2(value);
          });
        } else {
          // this.form.get('dividend').valueChanges.subscribe(value => this.calculateOfferToCreditors(value));
        }
        if(this.form.get('offer_to_creditors')) {
          this.form.get('offer_to_creditors').valueChanges.subscribe(value => {
            if (!this.formActive || this.isAutomationPaused) {
              return;
            }
            this.calculateDividend();
          });
        }
      });
    });
  }

  /*private calculateAllocatedUnifyePlan(){
    
    let unsecuredDMDebt = this.unsecuredDMDebt;
    let unsecuredDebt = this.unsecuredDebt;

    this.case.creditors.forEach(creditor => {
      unsecuredDMDebt += +creditor.pivot.debt_amount;
      unsecuredDebt += +creditor.pivot.initial_balance;
    });

    this.form.get('unifyePlan').patchValue(+(unsecuredDebt-unsecuredDMDebt).toFixed(2), {
      emitEvent: false, 
      onlySelf: true
    });

  }*/

  private refreshFormToDatabaseValues(caseCreditors: Array<CaseCreditor>): void {
    
    this.formActive = false;
    
    const creditors = this.form.get('creditors') as UntypedFormArray;
    
    while (creditors.length) {
      creditors.removeAt(0);
    }

    this.form.get('client_proposal')?.patchValue(this.case.proposal.term, {
      emitEvent: false,
      onlySelf: true,
    });

    this.form.get('distribution_fee').patchValue(+this.case.expense?.distribution_fee.toFixed(2), {
      emitEvent: false, 
      onlySelf: true
    });

    this.form.get('actual_disposable').patchValue(this.case.proposal.offer_to_creditors.toFixed(2), {
      emitEvent: false, 
      onlySelf: true
    });

    //this.calculateProposalTerm();
    //this.calculateClientFinalPayment();

  
    caseCreditors.forEach(caseCreditor => {
      this.addCreditor(caseCreditor);
    });
  
    creditors.controls.forEach(creditor => {
      creditor.get('offer_to_creditors').valueChanges.subscribe(() => {
        if (!this.formActive || this.isAutomationPaused) {
          return; 
        }
        this.calculateUnallocated();
        this.calculateFinalPayment(creditor);
        //this.calculateAllocatedUnifyePlan();
      });
      creditor.get('creditor_term').valueChanges.subscribe(() => {
        if (!this.formActive || this.isAutomationPaused) {
          return;
        }
        this.updateOfferAndFinalPayment(creditor);
        this.calculateUnallocated();
      });
    });

    this.formActive = true;
  }

  public changeDisposableIncomeSelect(): void {
    if (this.case.proposal){
      const thirdOptionValue = +this.case.proposal.offer_to_creditors + (this.case.expense?.distribution_fee || 0);
      const defaultDisposableOption = 
        (thirdOptionValue>0) 
          ? this.disposableOptions[2]?.value 
          : this.disposableOptions[0]?.value; 
      this.form.get('disposable_income').patchValue(defaultDisposableOption, {emitEvent: false, onlySelf: true});
    }
  }

  public closeModal(proposal: Proposal | null, dismissed: boolean): void {
    this.dialogRef.close({dismissed, data: proposal});
  }

  private buildForm(): void {

    this.formActive = false;

    const proposal = this.case.proposal;

    const totalInstallments = this.case.product.slug === 'cajaplus' ?
      Math.ceil(this.unsecuredDebt / this.disposableIncome) :
      (proposal?.term ? proposal.term : 60);

    const disposableIncomeDisabled  = this.case.product.slug === 'cajaplus';
    const offerToCreditorsDisabled  = this.case.product.slug === 'cajaplus';
    const totalInstallmentsDisabled = this.case.product.slug === 'cajaplus';

    // calculate distribution fee
    const distributionFeePercentageCalc = (this.disposableIncome * this.percentageDistributionFee) / 100;
    this.distributionFee = distributionFeePercentageCalc;
    if (distributionFeePercentageCalc < this.fixedDistributionFee) {
      this.distributionFee = this.fixedDistributionFee;
    }
    this.distributionFee = this.distributionFee * 1.21;
    const offerToCreditorsCalc = this.disposableIncome - this.distributionFee;
    // check if the case has distribution fee set in the expense table
    if (this.case.expense?.distribution_fee) {
      this.distributionFee = +this.case.expense.distribution_fee;
    }

    this.form = this.fb.group({
      total_income:             [this.financialOverview.income.toFixed(2), [Validators.required]],
      debt_level:               [this.unsecuredDebt.toFixed(2), [Validators.required]],
      total_expenses:           [this.financialOverview.expenses.toFixed(2), [Validators.required]],
      total_assets:             [this.financialOverview.assets.toFixed(2), [Validators.required]],
      disposable_income:        [
        {value: this.disposableOptions[0].value, disabled: disposableIncomeDisabled},
        //{value: defaultDisposableOption, disabled: disposableIncomeDisabled},
        [Validators.required],
      ],
      custom_disposable_income:          [null],
      actual_disposable:        [this.case.expense?.offer_to_creditors_total ?? (this.disposableIncome - this.distributionFee).toFixed(2)],
      distribution_fee:               [
        {value: this.case.expense?.distribution_fee ? Number(this.case.expense?.distribution_fee).toFixed(2) : Number(this.distributionFee).toFixed(2), disabled: false},
        [Validators.required],
      ],
      step_period:              [proposal?.step_period],
      step_offer:               [proposal?.step_offer],
      // offer_to_creditors:       [],
      term:                     [
        {value: totalInstallments, disabled: totalInstallmentsDisabled},
        [Validators.required]],
      dividend:                 [
        proposal?.dividend ? proposal.dividend : null,
        [Validators.required],
      ],
      payment_date:             [
        proposal?.payment_date ? new Date(proposal.payment_date) : this.setDefaultPaymentDate(),
        [Validators.required],
      ],
      unallocated: [Number(this.unallocated).toFixed(2)],
      creditor_payment_account: [proposal?.creditor_payment_account],
      payment_reference:        [proposal?.payment_reference],
      creditors: this.fb.array([]),
      client_proposal:          [null],
      client_final_payment:     [null],
      unifyePlan: [Number(this.unsecuredDebt-this.unsecuredDMDebt).toFixed(2)],
      automationToggle: [false],
    });
    this.addCreditorsToForm(this.case.creditors);
    this.formActive      = true;
    this.showStepPayment = !!proposal?.step_period;

    this.form.get('automationToggle').valueChanges.subscribe((isAutomationEnabled) => {
      this.toggleAutomationPause(!isAutomationEnabled);
    });

    ////// 
    //setTimeout(() => {
    //  this.formActive = false; // The form has been rebuilt
    //}, 0);
  }

  toggleAutomationPause(isPaused: boolean): void {
    this.isAutomationPaused = !isPaused;
    this.cdr.detectChanges();
  }

  private calculateDistributionFee(): void {
    const disposableIncome = Math.max(this.form.get('disposable_income').value.amount,0);
    const fixedDistributionFee = this.fixedDistributionFee;
    const percentageDistributionFee = this.percentageDistributionFee;
    const distributionFee = (disposableIncome * percentageDistributionFee) / 100;
    if (distributionFee < fixedDistributionFee) {
      this.form.get('distribution_fee').patchValue(+(fixedDistributionFee * 1.21).toFixed(2), {emitEvent: false, onlySelf: true});
    } else {
      this.form.get('distribution_fee').patchValue(+(distributionFee * 1.21).toFixed(2), {emitEvent: false, onlySelf: true});
    }
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>): void {
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        this.addCreditor(caseCreditor);
      });
    }
    this.calculateProposalTerm();
    this.calculateUnallocated();
    //this.calculateClientFinalPayment();
    //this.calculateAllocatedUnifyePlan();
    // subscribe to the offer_to_creditors changes
    const creditors = this.form.get('creditors') as UntypedFormArray;
    creditors.controls.forEach(creditor => {
      creditor.get('offer_to_creditors').valueChanges.subscribe(() => {
        if (!this.formActive || this.isAutomationPaused) {
          return;
        }
        this.calculateFinalPayment(creditor);
        this.calculateUnallocated();
        //this.calculateAllocatedUnifyePlan();
      });
      creditor.get('creditor_term').valueChanges.subscribe(() => {
        if (!this.formActive || this.isAutomationPaused) {
          return;
        }
        this.updateOfferAndFinalPayment(creditor);
        this.calculateUnallocated();
      });
    //   creditor.get('creditor_term').valueChanges.subscribe(() => {
    //     this.ifTermsChange()
    //   });
    });
  }

  // add creditors with offer_to_creditors to the form array
  private addCreditor(caseCreditor: CaseCreditor = null): void {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    const clientProposalControl = this.form.get('client_proposal');

    // check if the creditor has an offer to creditors
    if (caseCreditor.pivot.offer_to_creditor) {
      this.creditorHasOffer = true;
    }
    creditors.push(this.fb.group({
      case_creditor_id: [caseCreditor.pivot.id],
      creditor_id: [caseCreditor.id],
      creditor_name: [caseCreditor.name],
      debt_amount: [Number(caseCreditor.pivot.debt_amount).toFixed(2) ?? 0],
      initial_balance: [Number(caseCreditor.pivot.initial_balance).toFixed(2) ?? 0],
      offer_to_creditors: [Number(caseCreditor.pivot.offer_to_creditor).toFixed(2) ?? 0],
      final_payment: [Number(caseCreditor.pivot.final_payment).toFixed(2) ?? 0],
      creditor_term: [caseCreditor.pivot.terms, [creditorTermValidator(clientProposalControl)]],
      has_offer: [caseCreditor.pivot.offer_to_creditor ? true : false],
    }));
  }

  public getFormArray(): UntypedFormArray {
    return this.form.get('creditors') as UntypedFormArray;
  }

  private calculateDisposableIncome(): void {
    const disposable = Math.max(this.form.get('disposable_income').value.amount,0);
    const deudafixFee = Math.max(this.form.get('distribution_fee').value,0);

    //const disposableIncome = +(disposable - deudafixFee).toFixed(2);
    const disposableIncome = Math.max(+(disposable - deudafixFee).toFixed(2), 0);
    this.actualDisposable = disposableIncome;
    // Prevent infinite loop by disabling value change event
    this.form.get('actual_disposable').patchValue(disposableIncome.toFixed(2), {emitEvent: false, onlySelf: true});
  }

  private calculateOfferToCreditors(dividend: number): void {
    let offerToCreditors: number;
    const debtDevel  = this.form.get('debt_level').value;
    const term       = this.form.get('term').value;
    const stepOffer  = this.form.get('step_offer').value;
    const stepPeriod = this.form.get('step_period').value;

    if (this.showStepPayment) {
      offerToCreditors = +((
        ((+dividend / 100) * +debtDevel - (+stepOffer * +stepPeriod)) / (term - stepPeriod)
      ).toFixed(2));
    } else {
      offerToCreditors = +((((+dividend / 100) * +debtDevel) / +term).toFixed(2));
    }

    // Prevent infinite loop by disabling value change event
    this.form.get('actual_disposable').patchValue(offerToCreditors, {emitEvent: false, onlySelf: true});
  }

  private calculateOfferToCreditorsV2(legalFees: number): void {
    this.form.get('actual_disposable').patchValue(+((Math.max(this.disposableIncome - legalFees, 0)).toFixed(2)));
  }

  private calculateDividend(): void {
    let dividend: number;
    const offerToCreditors = Math.max(this.form.get('actual_disposable').value,0);
    // const term             = this.form.get('term').value;
    const debtLevel        = Math.max(this.form.get('debt_level').value,0);
    // const stepOffer        = this.form.get('step_offer').value;
    // const stepPeriod       = this.form.get('step_period').value;

    // if (this.showStepPayment) {
    //   const value = ((+offerToCreditors * (+term - stepPeriod) + (stepOffer * stepPeriod)) / +debtLevel).toFixed(4);
    //   dividend    = +(+value * 100).toFixed(2);
    // } else {
    //   const value = ((+offerToCreditors * +term) / +debtLevel).toFixed(4);
    //   dividend    = +(+value * 100).toFixed(2);
    // }
    dividend = 100;
    // calculate term based on dividing debt level by offer to creditors
    let term = 0;
    if (offerToCreditors > 0){
      term = Math.ceil(+debtLevel / +offerToCreditors);
    }
    
    this.form.get('term').patchValue(term, {emitEvent: false, onlySelf: true});

    // Prevent infinite loop by disabling value change event
    this.form.get('dividend').patchValue(dividend, {emitEvent: false, onlySelf: true});

    // update each offer to creditors in the form array based on the new dividend
    // if(!this.creditorHasOffer) {
      const creditors = this.form.get('creditors') as UntypedFormArray;
      creditors.controls.forEach(creditor => {
        creditor.get('creditor_term').updateValueAndValidity(); 
        const dmAmount = creditor.get('debt_amount').value;
        const clientDebtAmount = creditor.get('initial_balance').value;
        creditor.get('offer_to_creditors').patchValue(this.percentageOfDisposable(+debtLevel, +clientDebtAmount, dmAmount), {emitEvent: false, onlySelf: true});
        // calculate the final payment to creditors
        this.calculateFinalPayment(creditor);
      });
    // }
  }

  public submitForm(): void {
    // if (this.form.invalid) {
    //   this.form.markAllAsTouched();
    //   return;
    // }

    const requestData = {...this.form.getRawValue(), payment_date: this.form.get('payment_date').value.toLocaleDateString('en-CA')};

    this.isLoading++;
    this.caseService.generateProposal(this.case.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.case.proposal = result.data;
        this.toast.success(this.translateService.instant('CASES.details.generate-proposal-success'));
        this.closeModal(this.case.proposal, false);
      },
      error => {
        console.error(error);
        this.toast.error(this.translateService.instant('CASES.details.generate-proposal-error'));
      });
  }

  public addToCustom(): void {

    if (Number(this.form.get('custom_disposable_income').value) > 0){
      const newItem = {
        value: { index: 3, amount:  this.form.get('custom_disposable_income').value },
        label:  this.form.get('custom_disposable_income').value + '€ - ' + this.translateService.instant('customized'),
      };
      
      const firstLastIndex = this.disposableOptions.length - 1;
      const existingIndex = this.disposableOptions.findIndex(item => item.value.index === newItem.value.index);
    
      if (existingIndex !== -1) {
        this.disposableOptions[existingIndex] = newItem;
      } else {
        this.disposableOptions.splice(firstLastIndex, 0, newItem);
      }

      const selectedItem = this.disposableOptions.find(item => item.value.index === 3);
      this.form.get('disposable_income').patchValue(selectedItem?.value, {emitEvent: true, onlySelf: false});
    
    }

    this.showCustomFields=false;
    this.cdr.detectChanges();
    /*setTimeout(() => {
      this.showCustomFields = false;
    }, 0);*/
  }

  public stepPayment(): void {
    this.showStepPayment = !this.showStepPayment;
    if (!this.showStepPayment) { // remove value in form
      this.form.get('step_period').patchValue(null);
      this.form.get('step_offer').patchValue(null);
    }
    //  Update form values to recalculate `dividend` field
    this.form.updateValueAndValidity();
  }

  private getDistributionFees(): void {
    this.isLoading++;
    this.configService.getConfigData().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      const fixedDistributionFee      = result.data.find(obj => obj.key === 'fixed_distribution_fee').value;
      const percentageDistributionFee = result.data.find(obj => obj.key === 'percentage_distribution_fee').value;

      this.fixedDistributionFee      = fixedDistributionFee;
      this.percentageDistributionFee = percentageDistributionFee;
    });
  }

  public formatCurrency(value): string {
    // if value is null or undefined return 0
    if (!value) {
      return '0.00';
    }
    return value.toFixed(2);
  }

  public percentageOfDisposable(amount, initial_balance, dmamount): string {
    console.log("percentageOfDisposable function");
    console.log(amount, initial_balance, dmamount);
    const disposable = Math.max(this.form.get('actual_disposable').value,0);

    console.log("disposable" + disposable);
    // sum the initial balance of the creditors
    const creditors = this.form.get('creditors') as UntypedFormArray;
    let initial_balance_one = 0;
    creditors.controls.forEach(creditor => {
      //initial_balance_one += +creditor.get('initial_balance').value;
      initial_balance_one += +creditor.get('debt_amount').value;
    });
    console.log("initial balance one" + initial_balance_one);
    console.log("disposable" + disposable);
    let terms = 0;
    if (disposable > 0){
      terms = +(initial_balance_one / disposable);
    }
    
    let offer = 0;
    if (terms > 0){
      offer = (dmamount / terms);
    }

    console.log(terms);
    console.log(offer);
    
    return offer.toFixed(2);
  }

  public updateOfferAndFinalPayment(creditor): void {
    this.formActive = false;
    const creditorTerms = creditor.get('creditor_term').value;
    const currentBalance = creditor.get('debt_amount').value;
    const temp = Math.ceil(Number(currentBalance) / Number(creditorTerms));
    const offerToCreditors = Math.ceil(Number(currentBalance) / Number(creditorTerms)); 
    let total = (temp * Number(creditorTerms-1));
    let finalPayment = Number(currentBalance) - total;
    creditor.get('offer_to_creditors').patchValue(offerToCreditors, {emitEvent: false, onlySelf: true});
    creditor.get('final_payment').patchValue(finalPayment.toFixed(2), {emitEvent: false, onlySelf: true});
    this.formActive = true;
  }

  public calculateFinalPayment(creditor): void {
    this.formActive = false;
    // calculate the final payment based on debt level and offer to creditors and the terms
    const offerToCreditors = creditor.get('offer_to_creditors').value;
    const currentBalance = creditor.get('debt_amount').value;
    const temp = Math.floor(Number(currentBalance) / Number(offerToCreditors));
    const terms = Math.ceil(Number(currentBalance) / Number(offerToCreditors));
    let total = (temp * Number(offerToCreditors));
    let finalPayment = Number(currentBalance) - total;
    // update terms
    creditor.get('creditor_term').patchValue(terms, {emitEvent: false, onlySelf: true});
    creditor.get('final_payment').patchValue(finalPayment.toFixed(2), {emitEvent: false, onlySelf: true});
    this.formActive = true;
  }

  // check if the sum of the offer to creditors is equal or greater to the actual disposable
  public checkOfferToCreditors(): boolean {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    let sum = 0;
    creditors.controls.forEach(creditor => {
      sum += +creditor.get('offer_to_creditors').value;
    });
    return sum >= this.actualDisposable;
  }
  
  // calculate unallocated funds when creditor offer changes
  public calculateUnallocated(): void {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    let sum = 0;
    creditors.controls.forEach(creditor => {
      sum += +creditor.get('offer_to_creditors').value;
    });
    sum = +sum.toFixed(2);
    const actualDisposable = Math.max(this.form.get('actual_disposable').value,0);
    // console.log(actualDisposable, sum);

    this.unallocated = actualDisposable - sum;
    this.form.get('unallocated').patchValue(this.unallocated.toFixed(2), {emitEvent: false, onlySelf: true});
  }

  public isAllowedToGenerateProposal(): boolean {
    if (this.authUser) {
      return this.authUser.department_assignments.some(department => department.department_id === 5 || department.department_id === 13) || this.authUser.role_id === 5;
    }
    return false;
  }

  public setDefaultPaymentDate(): Date {
    const lastPhaseOneTerm = this.case.terms.filter(term => term.type === 'phase_one').pop();
    const date = lastPhaseOneTerm ? lastPhaseOneTerm.term_date : new Date();
    const formattedDate = new Date(date);
    formattedDate.setMonth(formattedDate.getMonth() + 1);
    return formattedDate;
  }

  public calculateProposalTerm(): void {
    let totalDebtAmount = 0;
    const creditors = this.form.get('creditors') as UntypedFormArray;
    creditors.controls.forEach(creditor => {
      totalDebtAmount += +creditor.get('initial_balance').value;
      //totalDebtAmount += +creditor.get('debt_amount').value;
    });
    const disposableIncome = Math.max(this.form.get('actual_disposable').value,0);
    this.form.get('client_proposal').patchValue(Math.ceil(totalDebtAmount / disposableIncome), {emitEvent: false, onlySelf: true});

    //DAM why term has to be different than client_proposal?
    this.form.get('term').patchValue(Math.ceil(totalDebtAmount / disposableIncome), {emitEvent: false, onlySelf: true});

  }

  public calculateClientFinalPayment(): void {
    const clientTerms = +Math.max(this.form.get('client_proposal').value,0);
    const actualDisposable = +Math.max(this.form.get('actual_disposable').value,0);
    const distributionFee = +Math.max(this.form.get('distribution_fee').value,0);
    const paidUntilLastMonth = +Math.max((clientTerms-1) * (actualDisposable),0);
    const finalPayment = +Math.max((this.unsecuredDebt-paidUntilLastMonth+distributionFee),0).toFixed(2);
    
    this.form.get('client_final_payment').patchValue(finalPayment, {emitEvent: false, onlySelf: true});
  }

  public ifTermsChange(): void {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    creditors.controls.forEach(creditor => {
      const currentBalance = creditor.get('debt_amount').value;
      creditor.get('offer_to_creditors').patchValue(this.percentageOfDisposable(+currentBalance, +currentBalance, 0), {emitEvent: false, onlySelf: true});
      this.calculateFinalPayment(creditor);
    });

  }
}
