import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Duration } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case/case.service';
// import { CaseListFiltersComponent } from './case-list-filters/case-list-filters.component';
import { environment } from '../../../../environments/environment';
import { MiscConfigService } from '../../config/misc-config/misc-config.service';
import { saveAs } from 'file-saver';
import { CaseCreditorService } from '../../case/case-creditor.service';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { DepartmentService } from '../../department/department.service';

@Component({
    templateUrl: './action.component.html',
    styleUrls:   ['./action.component.scss'],
})

export class ActionRequiredComponent implements OnInit {
  public bulkActionForm: UntypedFormGroup;
  public onlyRelated: boolean;
  public filterType: 'case' | 'legal' | 'affiliate_case' | 'customer_contact' | 'packager_non_transferred' = null;
  // End of wrapper

  public authUser: User;
  public isLoading       = 0;
  public isLoadingExport = 0;
  public submittingDistribution: boolean;

  public filtersReady = false;
  public caseListFilter: CaseListFilter;

  public percentageReduction: number;
  private subscriptions: Array<Subscription> = [];

  public form: UntypedFormGroup;

  // Datatable
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoadingCases                      = 0;
  public initialSelection                    = [];
  public allowMultiSelect                    = true;
  public selection                           = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public showInvoiceStatus: boolean;
  public pageSelected                        = false;
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Case>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public creditorDataSource: Array<string> = [];
  public negotiatorCreditorCols: Array<string> = [
    'creditor', 'creditor_status', 'debt_amount', 'negotiated_amount', 'percent_reduction'
  ];

  public displayedColumns: string[] = [
    'ref_number',
    // 'last_name',
    'negotiator',
    'case_status',
    'days_in_status',
    'reason',
  ];

  public departmentCategories: Array<DepartmentCategory> = [];
  public negotiatorsList: Array<User> = [];
  public selectedNegotiator: number;

  constructor(

    public globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
    private departmentService: DepartmentService,
  ) {

  }

  ngOnInit() {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user
      if(user.department_assignments.some(department => department.department.type === 'creditor-negotiator')) {
        this.onlyRelated = true;
      }
    }));
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 20, length: 1 };
    this.defaultSort            = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.fetchCases();
    this.fetchDepartmentCategories();
    this.buildForm();
  }

  private fetchDepartmentCategories() {
    this.subscriptions.push(
      this.departmentService.categoryIndex(['departments.users']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.departmentCategories = result.data.filter(department => department.type === 'creditor-negotiation');
        }),
      );
  }

  public buildForm() {
    this.form = new UntypedFormGroup({
      user_id: new FormControl(null),
    });
    this.form.get('user_id').valueChanges.subscribe(value => {
      this.selectedNegotiator = value;
      this.fetchCases();
    });
  }

  public fetchCases() {
    this.isLoadingCases++;
    const relations = ['packager_status', 'case_invoice_status', 'call_status', 'packager', 'affiliate', 'product', 'lead.affiliate',
      'payment_status', 'status', 'latest_case_status_log', 'oldest_case_pending_document', 'creditors', 'doc_status', 'case_status_logs', 'expense', 'distribution', 'department_with_user'];

    this.caseListFilter = {
      ...this.caseListFilter,
      all_active: true,
      only_related: this.onlyRelated ?? false,
      case_status_not_in: [335, 466],
      percentage_reduction_greater_then: 50,
      case_status_in: [504,502,500,334,506],
      case_payment_statuses: [1,2,4,11],
      dm_percent_accepted_greater: 70,
      page: this.paginatorConfig.pageIndex + 1,
      per_page: this.paginatorConfig.pageSize,

    };

    if(this.selectedNegotiator) {
      console.log(this.selectedNegotiator);
      this.caseListFilter['creditor-negotiator'] = [this.selectedNegotiator];
    } else {
      delete this.caseListFilter['creditor-negotiator'];
    }

    const requestData = MainBaseApiService.convertFiltersForRequest(
      { ...this.caseListFilter, with: relations }, 'get'
    );
    this.caseService.index(requestData)
      .pipe(finalize(() => this.isLoadingCases--))
      .subscribe(result => {
        this.dataSource             = new MatTableDataSource<Case>(result.data);
        this.dataSource.sort        = this.sort;
        this.paginatorConfig.length = result.meta.total;
        this.totalResults           = result.meta.total;
        this.totalPages             = result.meta.last_page;
      });
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
    this.creditorDataSource = [];
  }

  public creditorCount(clientCase) {
    let i = 0;
    clientCase.creditors.forEach(element => {
      if(element.pivot?.type == 'unsecured') {
        i++;
      }
    });
    return i;
  }

  public myCases() {
    this.caseListFilter.only_related = !this.caseListFilter.only_related;
    this.fetchCases();
  }
  
  public getDaysInStatus(element): number {
    if (element.latest_case_status_log.created_at) {
      return Math.ceil(DateTime.fromISO(element.latest_case_status_log.created_at).diffNow('days').negate().as('days'));
    }
  }
}