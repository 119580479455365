import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {AppSelectOption} from '../../../../../../_base-shared/contracts/common.interface';
import { DashboardService } from '../dashboard.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateTime } from 'luxon';

@Component({
    selector:    'app-missing-documents',
    templateUrl: './negotiator-leaderboard.component.html',
    styleUrls:   ['./negotiator-leaderboard.component.scss'],
})

export class NegotiatorLeaderboardComponent implements OnInit {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[] = [
        'agent_name',
        'solicitud',
        'refinanced',
        'aceptado',
        'porcentaje',
        // 'bonus',
    ];

    public period: string;
    public filterDataForm: UntypedFormGroup;

    public totalSolicitud = 0;
    public totalAceptado = 0;
    public totalRefinanced = 0;

    public negotiatorGoal = 30;
    public months: Array<AppSelectOption>  = [];

    public paginatorConfig = {
        pageIndex: 0,
        pageSize:  10,
        length:    1,
    };

    public leaderBonus = 250;
    public runnerUpBonus = 100;

    public isLoading = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private dashboardService: DashboardService
    ) {
    }

    ngOnInit(): void {
        this.populateMonths();
        console.log(this.months);
        this.buildForm();
        this.getNegotiatorLeaderboard();
    }
  
    public calculatePercentage(row: any): number {
        return ((row.case_count_aceptada + row.case_count_refinanced + row.case_count_solicitud) / this.negotiatorGoal) * 100;
    }

    private buildForm(): void {
        this.filterDataForm = this.fb.group({
            month_select: [this.months[0].value, Validators.required],
        });
        // month_select value change
        this.filterDataForm.get('month_select').valueChanges.subscribe(() => {
            this.dataSource = new MatTableDataSource([]);
            this.getNegotiatorLeaderboardSubmit();
        });
    }

    public populateMonths(): void {
        const today = new Date();
        const year  = today.getFullYear();
        const month = today.getMonth();

        // set the value to the start of the month and iterate backwards back a year
        for (let i = 0; i < 6; i++) {
            const date = new Date(year, month - i, 2);
            this.months.push({
                value: date.toISOString(),
                label: date.toLocaleString('default', { month: 'long' }) + ' ' + date.getFullYear(),
            });
        }
    }

    public getNegotiatorLeaderboard(): void {
        this.isLoading++;

        this.dashboardService.getNegotiatorLeaderboard({
            period: this.filterDataForm.get('month_select').value,
        }).subscribe((response) => {
            this.isLoading--;

            this.totalSolicitud = response.data.negotiators.reduce((acc, cur) => acc + +cur.case_count_solicitud, 0);
            this.totalAceptado = response.data.negotiators.reduce((acc, cur) => acc + +cur.case_count_aceptada, 0);
            this.totalRefinanced = response.data.negotiators.reduce((acc, cur) => acc + +cur.case_count_refinanced, 0);

            // add totalCount to each negotiator
            response.data.negotiators.forEach((negotiator) => {
                negotiator.totalCount = +negotiator.case_count_aceptada + +negotiator.case_count_refinanced + +negotiator.case_count_solicitud;
            });

            if (response.data.negotiators.length > 0) {
                response.data.negotiators[0].bonus = this.leaderBonus;
                response.data.negotiators[1].bonus = this.runnerUpBonus;
            }

            this.dataSource = new MatTableDataSource(response.data.negotiators);
            this.dataSource.data = this.dataSource.data.sort((a, b) => b.totalCount - a.totalCount);
        }, (error) => {
            this.isLoading--;
            console.error(error);
        });
    }

    public getNegotiatorLeaderboardSubmit(): void {
        this.getNegotiatorLeaderboard();
    }
    
    public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false) {
        if (endDay) {
            const inputValue = $event.value.toString();
            const dt         = DateTime.fromJSDate(new Date(inputValue));
            const endOfDay   = dt.endOf('day');
            const date       = endOfDay.toJSDate();
            this.filterDataForm.get(formControlName).patchValue(date);
        }
    }

    public checkIfNullNegotiated(row: any): string {
        if (row.case_count_aceptada === null || row.case_count_aceptada === 0) {
            return "noSales";
        }
    }
}
