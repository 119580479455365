import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../environments/environment';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-staff-navigation',
  templateUrl: './staff-navigation.component.html',
  styleUrls:   ['./staff-navigation.component.scss'],
})
export class StaffNavigationComponent implements OnInit {
  @Input() authUser: User;
  public unapproved: boolean;
  public isManager: boolean = false;

  constructor(public globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.unapprovedCreditors$.subscribe(unapproved => this.unapproved = unapproved);
    if (this.authUser) {
      this.isManager = environment.MANAGER_USER_IDS.includes(this.authUser.id);
    }
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  public userIsCreditorNegotiator(): boolean {
    return this.authUser.departments.some(department => department.type === 'creditor-negotiator') || (this.authUser.role?.slug === 'administrator' || this.authUser.role?.slug === 'super-admin');
  }
}
